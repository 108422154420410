import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	NumberField,
	BooleanField,
	EditButton,
	Edit,
	Show,
	Create,
	SimpleForm,
	SimpleShowLayout,
	TextInput,
	NumberInput,
	DisabledInput,
	Filter,
	BooleanInput
} from 'react-admin';

const CompanyFilter = props => (
	<Filter {...props}>
		<TextInput label="Buscar" source="name" alwaysOn />
		<BooleanInput label="Envío Automático" source="send_invoices" />
	</Filter>
);

export const CompanyList = props => (
	<List filters={<CompanyFilter />} {...props}>
		<Datagrid rowClick="edit">
			<TextField source="id" />
			<NumberField source="vat" />
			<TextField source="name" />
			<TextField source="commercial_name" />
			<BooleanField source="send_invoices" />
			<EditButton />
		</Datagrid>
	</List>
);

export const CompanyShow = props => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="name" />
			<TextField source="vat" />
			<TextField source="phone" />
			<TextField source="commercial_name" />
			<BooleanField source="send_invoices" />
		</SimpleShowLayout>
	</Show>
);

export const CompanyEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<DisabledInput source="id" />
			<DisabledInput source="name" />
			<DisabledInput source="vat" />
			<TextInput source="phone" />
			<TextInput source="commercial_name" />
			<BooleanInput source="send_invoices" />
		</SimpleForm>
	</Edit>
);

export const CompanyCreate = props => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="name" />
			<NumberInput source="vat" />
			<TextInput source="phone" />
			<TextInput source="commercial_name" />
			<BooleanInput source="send_invoices" />
		</SimpleForm>
	</Create>
);
