import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	NumberField,
	BooleanField,
	DateField,
	EditButton,
	Edit,
	Show,
	Create,
	SimpleForm,
	SimpleShowLayout,
	TextInput,
	NumberInput,
	DisabledInput,
	Filter,
	BooleanInput
} from 'react-admin';

const InvoicesFilter = props => (
	<Filter {...props}>
		<TextInput label="Buscar" source="name" alwaysOn />
		<BooleanInput label="Envío Automático" source="send_invoices" />
	</Filter>
);

const FullCustomerField = ({ record = {} }) => (
	<span>
		{record.customer_vat} {record.customer_name}
	</span>
);
FullCustomerField.defaultProps = { label: 'Cliente' };

const TotalAmountField = ({ record = {} }) => (
	<span>
		{record.currency === 'PEN' ? 'S/' : '$'}
		{record.amount_total.toFixed(2)}
	</span>
);
TotalAmountField.defaultProps = { label: 'Total' };

export const InvoicesList = props => (
	<List filters={<InvoicesFilter />} {...props}>
		<Datagrid rowClick="edit">
			<TextField source="name" />
			<DateField source="issue_date" />
			<FullCustomerField source="customer_name" />
			<TotalAmountField source="amount_total" />
			<TextField source="company_name" />
			<EditButton />
		</Datagrid>
	</List>
);

export const InvoicesShow = props => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="name" />
			<DateField source="issue_date" />
			<NumberField source="customer_vat" />
			<TextField source="customer_name" />
			<NumberField source="amount_total" />
		</SimpleShowLayout>
	</Show>
);

export const InvoicesEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<DisabledInput source="id" />
			<TextInput source="name" />
			<NumberInput source="vat" />
			<TextInput source="phone" />
			<TextInput source="commercial_name" />
			<BooleanInput source="send_invoices" />
		</SimpleForm>
	</Edit>
);

export const InvoicesCreate = props => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="name" />
			<NumberInput source="vat" />
			<TextInput source="phone" />
			<TextInput source="commercial_name" />
			<BooleanInput source="send_invoices" />
		</SimpleForm>
	</Create>
);
