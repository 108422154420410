// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

const BASE_URL = 'https://ruc.conflux.pe/api';
//const BASE_URL = 'http://localhost:5000/api';

export default (type, params) => {
	// called when the user attempts to log in
	if (type === AUTH_LOGIN) {
		const { username, password } = params;
		/*localStorage.setItem('token', username);
		// accept all username/password combinations
		return Promise.resolve();*/
		return fetch(`${BASE_URL}/auth/signin`, {
			method: 'POST',
			body: JSON.stringify({
				email: username,
				password: password
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => res.json())
			.catch(error => {
				console.error('Error:', error);
			})
			.then(response => {
				if (response.token) {
					console.log('Success:', response);
					localStorage.setItem('token', response.token);
				}
				console.error('Error:', response);
			});
	}
	// called when the user clicks on the logout button
	if (type === AUTH_LOGOUT) {
		localStorage.removeItem('token');
		return Promise.resolve();
	}
	// called when the API returns an error
	if (type === AUTH_ERROR) {
		const { status } = params;
		if (status === 401 || status === 403) {
			localStorage.removeItem('token');
			return Promise.reject();
		}
		return Promise.resolve();
	}
	// called when the user navigates to a new location
	if (type === AUTH_CHECK) {
		return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
	}
	return Promise.reject('Unknown method');
};
