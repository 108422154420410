import React, { Component } from 'react';
import {
	Admin,
	Resource,
	ListGuesser,
	EditGuesser,
	ShowGuesser
} from 'react-admin';
import spanishMessages from '@blackbox-vision/ra-language-spanish';

import dataProvider from './dataProvider';
import Dashboard from './Dashboard';
import authProvider from './authProvider';

import {
	CompanyList,
	CompanyShow,
	CompanyEdit,
	CompanyCreate
} from './pages/company';
import { UserList, UserShow, UserEdit, UserCreate } from './pages/user';
import {
	InvoicesList,
	InvoicesShow,
	InvoicesEdit,
	InvoicesCreate
} from './pages/invoices';

const messages = {
	es: spanishMessages
};

const i18nProvider = locale => messages[locale];

class App extends Component {
	render() {
		return (
			<Admin
				dataProvider={dataProvider}
				dashboard={Dashboard}
				authProvider={authProvider}
				locale="es"
				messages={messages}
				i18nProvider={i18nProvider}
			>
				<Resource
					name="user"
					list={ListGuesser}
					edit={EditGuesser}
					show={ShowGuesser}
				/>
				<Resource name="token" list={ListGuesser} />
				<Resource name="fix_pos" list={ListGuesser} />
				<Resource
					name="company"
					list={CompanyList}
					show={CompanyShow}
					edit={CompanyEdit}
					create={CompanyCreate}
				/>
				{/*<Resource
					name="user"
					list={UserList}
					show={UserShow}
					edit={UserEdit}
					create={UserCreate}
				/>*/}
				<Resource
					name="invoices"
					list={InvoicesList}
					show={InvoicesShow}
					edit={InvoicesEdit}
					create={InvoicesCreate}
				/>
			</Admin>
		);
	}
}
export default App;
